import React, { useEffect, useRef, useState } from "react";
import OrderDropdown from "../../../../components/OrdersDropDown";
import Dropdown from "../../../../components/Dropdown";
import Swal from "sweetalert2";
import {
  DELETE_CUSTOM_FILTER,
  GET_ALL_CUSTOM_FILTERS,
} from "../../../../API/Orders";
import { GET_ALL_EMPLOYEES } from "../../../../API/Employee";
import { message } from "antd";
import Icon from "../../../../components/Icon";
import useDarkMode from "use-dark-mode";
import NewCustomFilter from "./NewCustomFilter";
import CustomFilterDropdown from "./CustomFilterDropdown/index";
import AccessControl from "../../../../HOC/AccessControl";
const AllFiltersDropdown = ({
  onSelect,
  filtersReset,
  setFiltersReset,
  customFilters,
  onSelectFilter,
  setCustomFilters,
  activeFilterId,
  setActiveFilterId,
  selectedFilters,
  isNewFilterModalOpen,
  setIsNewFilterModalOpen,
}) => {
  const [editFilterId, setEditFilterId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [visibleFilters, setVisibleFilters] = useState([]);
  const [moreFilters, setMoreFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const PAGE_SIZE = 10;

  useEffect(() => {
    const visible = customFilters.slice(0, 3);
    const more = customFilters.slice(2);
    setVisibleFilters(visible);
    setMoreFilters(
      more.filter(
        (filter) =>
          !visible.some((visibleFilter) => visibleFilter.id === filter.id)
      )
    );
  }, [customFilters]);

  const handleFilterSelect = (filterId) => {
    setActiveFilterId(filterId);
    onSelectFilter(filterId);

    if (
      !visibleFilters.some((visibleFilter) => visibleFilter.id === filterId)
    ) {
      setActiveFilterId(filterId);
      onSelectFilter(filterId);

      const selectedFilter = moreFilters.find(
        (filter) => filter.id === filterId
      );

      if (selectedFilter) {
        let newVisibleFilters = [...visibleFilters];
        if (newVisibleFilters.length >= 3) {
          const removedFilter = newVisibleFilters.pop();
          setMoreFilters((prevMoreFilters) => [
            ...prevMoreFilters,
            removedFilter,
          ]);
        }
        newVisibleFilters = [...newVisibleFilters, selectedFilter];

        setVisibleFilters(newVisibleFilters);
        setMoreFilters((prevMoreFilters) =>
          prevMoreFilters.filter((filter) => filter.id !== filterId)
        );
      }
    }
  };
  const fetchEmployees = async (page) => {
    if (!hasMore || loading) return;
    
    setLoading(true);
    try {
      const data = await GET_ALL_EMPLOYEES(page, PAGE_SIZE);
      
      if (Array.isArray(data)) {
        if (page === 1) {
          setEmployees(data);
        } else {
          const newEmployees = data.filter(
            newEmp => !employees.some(existingEmp => existingEmp.id === newEmp.id)
          );
          setEmployees(prev => [...prev, ...newEmployees]);
        }
        setHasMore(data.length === PAGE_SIZE);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
      message.error("Failed to fetch employees");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees(1);
  }, []);

  useEffect(() => {
    if (filtersReset) {
      setSelectedEmployee(null);
      setFiltersReset(false);
    }
  }, [filtersReset, setFiltersReset]);
  const handleEmployeeSelect = async (employeeIdOrName) => {
    try {
      if (employeeIdOrName === "") {
        setSelectedEmployee(null);
        onSelect(null);
        return;
      }
      const selected = employees.find(
        (emp) => `${emp.full_name}` === employeeIdOrName
      );
      if (selected) {
        setSelectedEmployee(selected);
        onSelect(selected.id);
      }
    } catch (error) {
      console.error("Error selecting employee:", error);
    }
  };
  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading && hasMore) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchEmployees(nextPage);
    }
  };
  const dropdownOptions = [
    { label: "Assigned To", value: "" },
    ...employees.map((employee) => ({
      label: `${employee.full_name}`,
      value: employee.id,
    })),
  ];
  useEffect(() => {
    if (visibleModal) {
      fetchCustomFilters();
    }
  }, [visibleModal]);

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the custom filter",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      dangerMode: true,
    });
    if (result.isConfirmed) {
      try {
        await DELETE_CUSTOM_FILTER(id);
        message.success("Custom filter deleted successfully");
        setCustomFilters((prevFilters) =>
          prevFilters.filter((filter) => filter.id !== id)
        );
      } catch (error) {
        message.error("Failed to delete custom filter", error);
      }
    }
  };
  const handleEdit = (id) => {
    setEditFilterId(id);
    setIsNewFilterModalOpen(true);
  };
  const showNewFilterModal = () => {
    setEditFilterId(null);
    setIsNewFilterModalOpen(true);
  };
  const handleNewFilterModalOk = () => {
    setIsNewFilterModalOpen(false);
  };
  const handleNewFilterModalCancel = () => {
    setIsNewFilterModalOpen(false);
    setEditFilterId(null);
  };
  const darkMode = useDarkMode(false);
  const fetchCustomFilters = async () => {
    setLoading(true);
    try {
      const response = await GET_ALL_CUSTOM_FILTERS();
      if (Array.isArray(response.data)) {
        setCustomFilters(response.data);
        message.success("Custom filters fetched successfully!");
      } else {
        console.error("Expected an array but got:", typeof response.data);
        message.error("Unexpected response format.");
      }
    } catch (error) {
      console.error("Failed to fetch custom filters:", error);
      message.error("Failed to fetch custom filters. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const renderFilter = (filter) => (
    <OrderDropdown
      key={filter.id}
      value={filter.custom_filter_name}
      setValue={() => {}}
      options={[
        {
          label: "Edit View",
          icon: <Icon name="edit" size={16} />,
          onClick: () => handleEdit(filter.id),
        },
        {
          label: <span className="text-red-500">Delete View</span>,
          icon: <Icon name="trash" size={16} color="#FF6A55" />,
          onClick: () => handleDelete(filter.id),
        },
      ]}
      classDropdownHead={`px-4 py-2 rounded-md transition-colors flex justify-between items-center ${
        activeFilterId === filter.id
          ? darkMode.value
            ? "bg-[#272B30]"
            : "bg-gray-300"
          : ""
      }`}
      onLabelClick={() => handleFilterSelect(filter.id)}
    />
  );
  return (
    <div className="flex flex-wrap gap-4 sm:flex-col sm:gap-2 w-full">
     <AccessControl moduleCode="eV79MY" requiredPrivilege="xPGbZk">
         <Dropdown
          value={selectedEmployee ? selectedEmployee.full_name : "Assigned To"}
          setValue={handleEmployeeSelect}
          options={employees.map((employee) => employee.full_name)}
          className="custom-dropdown"
          scroll={true}
          onScroll={handleScroll}
          loading={loading}
        />
      </AccessControl>
      {visibleFilters.slice(0, 2).map(renderFilter)}
      {visibleFilters.length === 3 && renderFilter(visibleFilters[2])}

      {moreFilters.length > 0 && (
        <CustomFilterDropdown
          value="More"
          setValue={() => {}}
          options={moreFilters
            .filter(
              (filter) =>
                !visibleFilters.some((visible) => visible.id === filter.id)
            )
            .map((filter) => ({
              label: filter.custom_filter_name,
              onClick: () => handleFilterSelect(filter.id),
            }))}
        />
      )}

      <div className="flex items-center space-x-2" onClick={showNewFilterModal}>
        <Icon name="plus-circle" size="24" fill="#6F767E" />
      </div>
      <NewCustomFilter
        isNewFilterModalOpen={isNewFilterModalOpen}
        handleNewFilterModalOk={handleNewFilterModalOk}
        handleNewFilterModalCancel={handleNewFilterModalCancel}
        editFilterId={editFilterId}
        fetchCustomFilters={fetchCustomFilters}
        selectedFilters={selectedFilters}
      />
    </div>
  );
};
export default AllFiltersDropdown;
