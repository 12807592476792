import React from "react";
import cn from "classnames";
import styles from "./Products.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../../../../components/Icon";
import OrderImageIndex from "../OrderImageIndex/OrderImageIndex";
import Slider from "react-slick";

const Products = ({ orderDetails, handleRemoveAllMedia, handleDelete }) => {
  if (!orderDetails || !orderDetails.OrderMedia) {
    return null;
  }

  return (
    <div className={styles.products}>
      <div className={styles.head}>
        <div className={cn("title-green", styles.title)}>
          <div>Captured Media</div>
          <div className="flex gap-7">
            <div className="text-xs leading-4 font-semibold text-gray-600">
              {orderDetails.image_count < 10
                ? `0${orderDetails.image_count}`
                : orderDetails.image_count}{" "}
              {""} Images
            </div>
            <div className="text-xs leading-4 font-semibold text-gray-600">
              {/* {orderDetails.video_count}  */}
              {orderDetails.video_count < 10
                ? `0${orderDetails.video_count}`
                : orderDetails.video_count}
              {""}
              {""} Video
            </div>
          </div>
        </div>

        {orderDetails.OrderMedia.length > 0 && (
          <div onClick={handleRemoveAllMedia}>
            <button className={cn("button-stroke button-small", styles.button)}>
              <span>Remove All</span>
            </button>
          </div>
        )}
      </div>

      <div className={styles.mediaContainer}>
        <>
          {orderDetails.OrderMedia.map((media, index) => (
            <OrderImageIndex
              // className={styles.product}
              className={styles.mediaItem}
              item={media}
              key={index}
              handleDelete={handleDelete}
              withoutCheckbox // Corrected typo in prop name
            />
          ))}
        </>
      </div>
    </div>
  );
};

export default Products;
