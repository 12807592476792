import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, Navigate, Outlet } from "react-router-dom";
import cn from "classnames";
import styles from "./Search.module.sass";
import Icon from "../../Icon";
import Item from "./Item";
import Suggestion from "./Suggestion";

import { search_items } from "../../../API/Search";

const Search = ({ className }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]); // Store search results

  const cancelTokenRef = useRef(null); // Ref to store the cancel token

  const handleOrderRouting = (id) => {
    navigate(`/order-details/${id}`);
    setSearchTerm("");
  };
  const handleStaffRouting = (id) => {
    navigate(`/staff/edit/${id}`);
    setSearchTerm("");
  };
  useEffect(() => {
    if (!searchTerm) {
      setVisible(false);
      setResults([]); // Clear results when search term is empty
      return;
    }

    const fetchResults = async () => {
      setVisible(true);

      try {
        const response = await search_items(searchTerm, cancelTokenRef);
        setResults(response);
      } catch (error) {
        console.error("Search error:", error);
      }
    };

    // Debounce the search request
    const debounceTimeout = setTimeout(fetchResults, 300);

    // Cleanup function to cancel request and timeout if component unmounts or searchTerm changes
    return () => {
      clearTimeout(debounceTimeout);
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel();
      }
    };
  }, [searchTerm]);

  return (
    <>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name="search" size="24" />
          </button>
          <button className={styles.direction}>
            <Icon name="arrow-left" size="24" />
          </button>
          <input
            className={styles.input}
            type="text"
            placeholder="Search for orders, products or staff members"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Trigger search on input change
          />

          <button className={styles.close} onClick={() => setSearchTerm("")}>
            <Icon name="close-circle" size="24" />
          </button>
        </div>
        <div className={styles.body}>
          {results?.orders && results?.orders?.length ? (
            <div className={styles.box}>
              <div className={styles.category}>Orders</div>
              <div className={styles.list}>
                {results?.orders?.map((result, index) => (
                  <Item
                    className={styles.item}
                    item={result}
                    key={index}
                    onClick={() => [
                      handleOrderRouting(result.data.order_number),
                    ]}
                  />
                ))}
              </div>
            </div>
          ) : (
            ""
          )}

          {results?.employees && results?.employees.length ? (
            <div className={styles.box}>
              <div className={styles.category}>Employees</div>
              <div className={styles.list}>
                {results?.employees?.map((employee, index) => (
                  <Suggestion
                    className={styles.item}
                    item={employee}
                    key={index}
                    onClick={() => {
                      handleStaffRouting(employee.data.id);
                    }}
                  />
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Search;
