import custom_axios from "../axios";

export const GET_ALL_EMPLOYEES = async (page, pageSize) => {
  try {
    const response = await custom_axios.get(`/employee/all-active`, {
      params: {
        page,
        pageSize
      }
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching employees:", error);
    throw error;
  }
};
export const GET_ALL_EMPLOYEES_STAFF_MODULE = async ({ page, pageSize, keywords }) => {
  try {
    const queryParams = new URLSearchParams({
      page: page,
      pageSize: pageSize,
      ...(keywords && { keywords: keywords })
    });

    const response = await custom_axios.get(`/employee/all?${queryParams}`);
    return {
      data: response.data.data,
      count: response.data.count
    };
  } catch (error) {
    console.error("Error fetching employee data:", error);
    throw error;
  }
};
export const GET_EMPLOYEE = async () => {
  try {
    const response = await custom_axios.get(`/employee`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching employee details:", error);
    throw error;
  }
};

export const SEND_SELECTED_EMPLOYEE_ID = async (orderIds) => {
  if (!orderIds || orderIds.length === 0) {
    throw new Error("No order IDs provided");
  }
  try {
    const response = await custom_axios.put(`/employee/order-unassign`, {
      order_id_list: orderIds,
    });
    return response;
  } catch (error) {
    console.error("Error unassigning orders:", error);
    throw error;
  }
};

export const ASSIGN_ORDERS = async (formData) => {
  try {
    const response = await custom_axios.post(`/employee/order-assignment`, {
      assign_to: formData.assign_to,
      order_id_list: formData.order_id_list,
    });
    return response;
  } catch (error) {
    console.error("Error un-assigning orders:", error);
    throw error;
  }
};
export const GET_ALL_EMPLOYEES_PAGINATION = async () => {
  try {
    const response = await custom_axios.get(`/employee/all?pageSize=100`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching employees with pagination:", error);
    throw error;
  }
};
export const DELETE_STAFF = async (id) => {
  try {
    const response = await custom_axios.delete("/employee/delete", {
      data: { id: parseInt(id) }
    });
    return response;
  } catch (error) {
    console.error("Error deleting employee:", error);
    throw error;
  }
};
export const ACTIVATE_STAFF = async (id) => {
  try {
    const response = await custom_axios.post(`/employee/reactivate/${id}`);
    return response;
  } catch (error) {
    console.error("Error reactivating employee:", error);
    throw error;
  }
};
export const DEACTIVATE_STAFF = async (id) => {
  try {
    const response = await custom_axios.post(`/employee/deactivate/${id}`);
    return response;
  } catch (error) {
    console.error("Error deactivating employee:", error);
    throw error;
  }
};
