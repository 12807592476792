import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./View.module.sass";
import Item from "../../Item";

import Table from "../Table";
import { get_all_permitted_roles } from "../../../../API/RBAC";
import { useMenu } from "../../../../context/Roles/MenuContext";
import { Formik, Form } from "formik";
import { useFormikContext } from "../../context/SettingsContext";
const View = () => {
  const { formikRef } = useFormikContext();
  const [visible, setVisible] = useState(false);
  const [roles, setRoles] = useState([]);
  const { setSubActiveTab } = useMenu();
  const handleCreateRole = () => {
    setSubActiveTab(2);
  };

  const fetchAllRoles = async () => {
    const res = await get_all_permitted_roles();
    setRoles(res.data);
  };

  useEffect(() => {
    fetchAllRoles();
  }, []);
  const initialValues = {
    name: "",
  };
  return (
    <Item className={cn(styles.card)} title="Manage Roles" classTitle="title-green">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleCreateRole}
        innerRef={formikRef}
      >
        {({ isSubmitting, setFieldValue, values, errors, touched }) => (
          <Form>{/* Empty form to trigger formik ref */}</Form>
        )}
      </Formik>
      <div className={styles.list}>
        <Table
          data={roles}
          className={styles.table}
          activeTable={visible}
          setActiveTable={setVisible}
        />
      </div>
    </Item>
  );
};

export default View;
