import React, { useEffect, useState, useRef, useCallback } from "react";
import cn from "classnames";
import { Divider, Space } from "antd";
import styles from "../Released.module.sass";
import custome_axios from "../../../../axios";
import Modal from "../../../../components/Modal";
import Radio from "../../../../components/Radio";
import CustomToast from "../../../../components/CustomToast";
import { GET_ALL_EMPLOYEES } from "../../../../API/Employee";

function AssignToPickerOrPacker({
  setAssignVisibleModal,
  assignvisibleModal,
  checkedItems,
  updateFilter,
  handleCheckboxChange,
}) {
  const ids = Object.keys(checkedItems);
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const PAGE_SIZE = 10;

  const observer = useRef();
  const lastStaffElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const checkedIds = Object.keys(checkedItems).filter((id) => checkedItems[id]);
  const showToast = CustomToast();

  const fetchEmployees = async (currentPage) => {
    try {
      setLoading(true);
      const employees = await GET_ALL_EMPLOYEES(currentPage, PAGE_SIZE);

      const newRoles = employees.map((item) => ({
        label: `${item.full_name}`,
        value: item.id,
      }));

      if (currentPage === 1) {
        setStaffOptions([{ label: "None", value: null }, ...newRoles]);
      } else {
        setStaffOptions((prevOptions) => [...prevOptions, ...newRoles]);
      }
      setHasMore(newRoles.length === PAGE_SIZE);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        showToast("Permission denied", "error");
      } else {
        showToast("Failed to fetch employees", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees(page);
  }, [page]);

  useEffect(() => {
    setSelectedRole(null);
  }, []);

  const handleOk = async () => {
    setAssignVisibleModal(false);
    try {
      await custome_axios.post("/employee/order-assignment", {
        assign_to: selectedRole,
        order_id_list: checkedIds.map(Number),
      });

      showToast(`Orders successfully assigned.`, "success");
      updateFilter();

      const newCheckedItems = {};
      checkedIds.forEach((id) => (newCheckedItems[id] = false));
      handleCheckboxChange(null, newCheckedItems);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        showToast("Only admin can access", "error");
      } else {
        showToast("Failed to assign orders.", "error");
      }
    }
  };

  const handleCancel = () => {
    setAssignVisibleModal(false);
  };

  return (
    <Modal
      outerClassName={styles.outer}
      visible={assignvisibleModal}
      onClose={() => setAssignVisibleModal(false)}
    >
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>
          Assign To Picker / Packer
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <Divider />
            <div
              className={cn(
                styles.radioContainer,
                "max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-400/50 hover:scrollbar-thumb-gray-400/60 scrollbar-thumb-rounded-full"
              )}
            >
              <Space direction="vertical">
                {staffOptions
                  .filter((role) => role.value !== null) 
                  .map((role, index) => (
                    <div
                      ref={
                        index === staffOptions.length - 2
                          ? lastStaffElementRef
                          : null
                      }
                      key={`${role.value}-${index}`}
                    >
                      <Radio
                        value={selectedRole === role.value}
                        onChange={() => setSelectedRole(role.value)}
                        content={role.label}
                        name="staff"
                        reverse
                      />
                    </div>
                  ))}
              </Space>
            </div>

            <div className={cn(styles.btns, "mt-5 mr-[10px]")}>
              <button
                className={cn("button-stroke mr-3", styles.button)}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className={cn("button", styles.button)}
                onClick={handleOk}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AssignToPickerOrPacker;
