import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import Icon from "../../../components/Icon";
import useDarkMode from "use-dark-mode";
import cn from "classnames";
import styles from "./OrderLineItemsIndex.module.sass";
import Card from "../../../components/Card";
import CustomAlert from "../../../components/CustomAlert";
import { COMPLETE_PACK_ORDERS } from "../../../API/Scanner";
import CustomToast from "../../../components/CustomToast";
import errorSound from "../../../assets/Sounds/error.wav";
import successSound from "../../../assets/Sounds/Success.wav";
import Avatar from "../../../assets/images/Profile Avatar Light.svg";
import Modal from "../../../components/Modal";
const OrderLineItems = ({ orderData, onSelectionChange, scannedBarcode, productVerified,setIsPackingInProgress }) => {

  const [products, setProducts] = useState([]);
  const [pendingPackedProducts, setPendingPackedProducts] = useState([]);
  const darkMode = useDarkMode(false);
  const { showAlert } = CustomAlert();
  const [scannedCount, setScannedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const showToast = CustomToast();
  const errorAudio = useRef(new Audio(errorSound));
  const successAudio = useRef(new Audio(successSound));
  const [visibleModal, setVisibleModal] = useState(false);
  const handleOpenModal = () => {
    setVisibleModal(true);
  };
  useEffect(() => {
    if (orderData && orderData.LineItems) {
      const initialProducts = orderData.LineItems.map((item) => ({
        id: item?.id ?? `null`,
        product_id: item?.product_id ?? null,
        variant_id: item?.variant_id ?? null,
        title: item?.name ?? "Unknown Product",
        sku: item?.sku ?? "N/A",
        barcode: item?.product_variant?.barcode ?? "N/A",
        location: item?.product_variant?.variant_location ?? "N/A",
        quantity: parseInt(item?.quantity ?? "0", 10),
        packedQuantity: 0,
        product: {
          id: item?.product?.id ?? null,
          product_id: item?.product?.product_id ?? null,
          product_type: item?.product?.product_type ?? "Unknown",
          ProductImage: item?.product?.ProductImage ?? [],
        },
        product_variant: {
          ...(item?.product_variant ?? {}),
        },
        image:
          item?.product?.ProductImage?.[0]?.src ?? "/api/placeholder/100/100",
        verified_by_ai: item.verified_by_ai,
      }));

      setProducts(initialProducts);
      setPendingPackedProducts([]);
      setTotalCount(
        initialProducts.reduce((sum, product) => sum + product.quantity, 0)
      );
      if (orderData?.OrderDetails?.note) {
        setVisibleModal(true);
      }
    } else {
      setProducts([]);
      setPendingPackedProducts([]);
      setTotalCount(0);
    }
    setScannedCount(0);
  }, [orderData]);
  useEffect(() => {
    if (scannedBarcode) {
      setProducts((currentProducts) =>
        currentProducts.map((product) => {
          if (product.product_variant?.sku === scannedBarcode) {
            if (product.packedQuantity < product.quantity) {
              setScannedCount((prevCount) => prevCount + 1);
              const newPackedQuantity = Math.min(
                product.packedQuantity + 1,
                product.quantity
              );
              setPendingPackedProducts((prevPendingProducts) => {
                const existingProductIndex = prevPendingProducts.findIndex(
                  (p) => p.product_id === product.product.id
                );

                if (existingProductIndex !== -1) {
                  const updatedPendingProducts = [...prevPendingProducts];
                  updatedPendingProducts[existingProductIndex] = {
                    ...updatedPendingProducts[existingProductIndex],
                    is_packed: newPackedQuantity === product.quantity,
                    packed_count: newPackedQuantity,
                  };
                  return updatedPendingProducts;
                } else {
                  return [
                    ...prevPendingProducts,
                    {
                      product_id: product.product.id,
                      variant_id: product.product_variant?.id,
                      is_packed: newPackedQuantity === product.quantity,
                      packed_count: newPackedQuantity,
                      total_count: product.quantity,
                    },
                  ];
                }
              });

              return {
                ...product,
                packedQuantity: newPackedQuantity,
              };
            } else {
              errorAudio.current.play();
              showToast(
                `${product.title} has already been fully scanned`,
                "error"
              );
              return product;
            }
          }
          return product;
        })
      );
    }
  }, [scannedBarcode]);
  useEffect(() => {
    const allPackedProducts = products
      .filter((product) => product.packedQuantity > 0)
      .map((product) => ({
        product_id: product.product.id,
        variant_id: product.product_variant?.id,
        is_packed: product.packedQuantity === product.quantity,
        packed_count: product.packedQuantity,
        total_count: product.quantity,
      }));

    setPendingPackedProducts(allPackedProducts);
  }, [products]);

  useEffect(() => {
    onSelectionChange(pendingPackedProducts);
  }, [pendingPackedProducts, onSelectionChange]);

  const handleCompletePack = useCallback(async () => {
    const result = await showAlert(
      "Warning",
      "Some items are not scanned. Are you sure you want to mark everything as packed?",
      "warning",
      "Yes",
      "Cancel"
    );

    if (result.isConfirmed) {
      try {
        const updatedProducts = products.map((product) => ({
          ...product,
          packedQuantity: product.quantity,
        }));

        const totalItems = updatedProducts.reduce(
          (sum, product) => sum + product.quantity,
          0
        );
        setScannedCount(totalItems);

        const completedPackedProducts = updatedProducts.map((product) => ({
          product_id: product.product.id,
          variant_id: product.product_variant.id,
          is_packed: true,
          packed_count: product.quantity,
          total_count: product.quantity,
        }));

        setPendingPackedProducts(completedPackedProducts);

        const payload = {
          order_id: orderData.id,
          products: completedPackedProducts,
        };

        await COMPLETE_PACK_ORDERS(payload);
        showToast("Order packed successfully!", "success");
        setProducts(updatedProducts);
        setIsPackingInProgress(false);
      } catch (error) {
        console.error("Error in handleCompletePack:", error);
        showToast("An unexpected error occurred. Please try again.", "error");
      }
    }
  }, [orderData, products, showAlert, showToast]);

  const handleReset = useCallback(() => {
    setProducts((currentProducts) =>
      currentProducts.map((product) => ({
        ...product,
        packedQuantity: 0,
      }))
    );

    setPendingPackedProducts([]);
    setScannedCount(0);
    setIsPackingInProgress(false);
  }, []);

  const handleItemPack = useCallback((product) => {
    if (product.packedQuantity < product.quantity) {
      successAudio.current.play();
      setProducts((currentProducts) =>
        currentProducts.map((p) =>
          p.id === product.id
            ? {
                ...p,
                packedQuantity: p.packedQuantity + 1,
              }
            : p
        )
      );
      setScannedCount((prevCount) => prevCount + 1);
      setPendingPackedProducts((prevPendingProducts) => {
        const existingProductIndex = prevPendingProducts.findIndex(
          (p) => p.product_id === product.product.id
        );
        const newPackedCount = product.packedQuantity + 1;

        if (existingProductIndex !== -1) {
          // Update existing product
          const updatedPendingProducts = [...prevPendingProducts];
          updatedPendingProducts[existingProductIndex] = {
            ...updatedPendingProducts[existingProductIndex],
            is_packed: newPackedCount === product.quantity,
            packed_count: newPackedCount,
          };
          return updatedPendingProducts;
        } else {
          // Add new product
          return [
            ...prevPendingProducts,
            {
              product_id: product.product.id,
              variant_id: product.product_variant?.id,
              is_packed: newPackedCount === product.quantity,
              packed_count: newPackedCount,
              total_count: product.quantity,
            },
          ];
        }
      });
    }
  }, []);

  const packedProducts = useMemo(() => {
    return products
      .filter((product) => product.packedQuantity > 0)
      .map((product) => ({
        product_id: product.product.id,
        variant_id: product.product_variant?.id,
        is_packed: product.packedQuantity === product.quantity,
        packed_count: product.packedQuantity,
        total_count: product.quantity,
      }));
  }, [products]);

  const handleQuantityChange = useCallback((id, increment) => {
    successAudio.current.play();
    setProducts((currentProducts) => {
      let newScannedCount = 0;
      const updatedProducts = currentProducts.map((product) => {
        if (product.id === id) {
          const newPackedQuantity = Math.max(
            0,
            Math.min(product.packedQuantity + increment, product.quantity)
          );
          newScannedCount += newPackedQuantity;

          setPendingPackedProducts((prevPendingProducts) => {
            const existingProductIndex = prevPendingProducts.findIndex(
              (p) => p.product_id === product.product.id
            );

            if (existingProductIndex !== -1) {
              const updatedPendingProducts = [...prevPendingProducts];
              updatedPendingProducts[existingProductIndex] = {
                ...updatedPendingProducts[existingProductIndex],
                is_packed: newPackedQuantity === product.quantity,
                packed_count: newPackedQuantity,
              };
              return updatedPendingProducts;
            } else if (newPackedQuantity > 0) {
              return [
                ...prevPendingProducts,
                {
                  product_id: product.product.id,
                  variant_id: product.product_variant?.id,
                  is_packed: newPackedQuantity === product.quantity,
                  packed_count: newPackedQuantity,
                  total_count: product.quantity,
                },
              ];
            }
            return prevPendingProducts;
          });

          return {
            ...product,
            packedQuantity: newPackedQuantity,
          };
        } else {
          newScannedCount += product.packedQuantity;
          return product;
        }
      });
      setScannedCount(newScannedCount);
      return updatedProducts;
    });
  }, []);
  const getStatusIcon = useCallback((product) => {
    if (product.packedQuantity === 0) {
      return <Icon name="itemsclock" size="24" fill="#6F767E" />;
    }
    if (product.packedQuantity < product.quantity) {
      return <Icon name="pending" size="24" fill="#FF8A00" />;
    }
    return <Icon name="packed" size="24" fill="#39A66A" />;
  }, []);

  const sortedProducts = useMemo(() => {
    return [...products].sort((a, b) => {
      if (a.packedQuantity === a.quantity && b.packedQuantity !== b.quantity)
        return 1;
      if (b.packedQuantity === b.quantity && a.packedQuantity !== a.quantity)
        return -1;
      return 0;
    });
  }, [products]);
  const findProductImage = (item) => {
    if (item.product_variant && item.product_variant.image_src) {
      return item.product_variant.image_src
        ? item.product_variant.image_src
        : null;
    } else {
      if (item.product?.ProductImage) {
        return item.product.ProductImage[0]?.src;
      }
      return null;
    }
  };
  return (
    <>
      <Card
        className={styles.card}
        title={!orderData ? "Items" : null}
        classTitle={!orderData ? "title-purple" : null}
      >
        {orderData && (
          <div className={styles.head}>
            <div className={cn("title-purple", styles.title)}>
              <div className="flex">
                <div>
                  <div style={{ fontSize: "20px" }}>
                    {orderData?.name ? `${orderData?.name}` : "Items"}
                  </div>
                  <div className="flex gap-7">
                    <div className="text-xs leading-4 font-semibold text-gray-600">
                      Items
                    </div>
                  </div>
                </div>
                <div className={cn(styles.counter)}>
                  {scannedCount}/{totalCount}
                </div>
              </div>
            </div>

            <div>
              <button
                className={cn("button", styles.button)}
                onClick={handleCompletePack}
              >
                Complete Pack
              </button>
              <button
                type="button"
                className={cn("button-stroke mr-[10px]", styles.button)}
                onClick={handleReset}
              >
                Reset
              </button>
              <button
                type="button"
                className="button-stroke mr-[8px]"
                onClick={handleOpenModal}
              >
                <Icon name="note_info" size="24" /> Notes
              </button>
            </div>
          </div>
        )}

        {!orderData ? (
          <div>Scan product(s) to see the items here</div>
        ) : (
          <div className="space-y-2">
            {sortedProducts.map((product) => (
              <div
                key={product.id}
                className={`flex items-center pt-0 pb-[10px] px-[21px] rounded-[20px] ${
                  product.packedQuantity === product.quantity
                    ? "bg-[#B5E4CA5E]"
                    : product.packedQuantity > 0
                    ? "bg-[#FFF3E1]"
                    : ""
                }`}
              >
                <div className="flex-shrink-0 mr-3">
                  {getStatusIcon(product)}
                </div>
                <img
                  src={findProductImage(product)}
                  // src={product.image}
                  alt={product.title}
                  className="w-10 h-10 object-cover rounded mr-3"
                />
                <div className="flex-grow">
                  <h3
                    className={`font-semibold ${
                      darkMode.value &&
                      ((product.packedQuantity > 0 &&
                        product.packedQuantity < product.quantity) ||
                        product.packedQuantity === product.quantity)
                        ? "text-black"
                        : ""
                    }`}
                  >
                    {product.title}
                  </h3>

                  <div className="grid grid-cols-5 gap-2 text-sm text-gray-600">
                    <div>
                      <div className="font-semibold">
                        <span className={cn(styles.LineItems)}>SKU</span>
                      </div>
                      <div>{product.product_variant.sku || "N/A"}</div>
                    </div>
                    <div>
                      <div className="font-semibold">
                        <span className={cn(styles.LineItems)}>Barcode</span>
                      </div>
                      <div>
                        {product.product_variant.barcode
                          ? product.product_variant.barcode
                          : "-"}
                      </div>
                    </div>
                    <div>
                      <div className="font-semibold">
                        <span className={cn(styles.LineItems)}>Location</span>
                      </div>
                      <div>
                        {product.product_variant.variant_location || "N/A"}
                      </div>
                    </div>
                    {process.env.REACT_APP_SHOW_BETA_FEATURE == "ON" ? (
                      <div>
                        <div className="font-semibold">
                          <span className={cn(styles.LineItems)}>
                            Verification
                          </span>
                        </div>
                        <div>
                          <p
                            className={
                              productVerified?.includes(product.product_id)
                                ? styles.verified
                                : styles.unverified
                            }
                          >
                            {" "}
                            {productVerified?.includes(product.product_id)?"Verified":"Unverified"}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="flex gap-2 items-center">
                      <span className="mr-2 whitespace-nowrap">
                        {product.packedQuantity} / {product.quantity}
                      </span>
                      <div
                        className="inline-flex rounded-md shadow-sm"
                        role="group"
                      >
                        <button
                          onClick={() => handleQuantityChange(product.id, -1)}
                          className={`px-2 py-2 text-sm font-medium ${
                            darkMode.value
                              ? "text-[black] hover:border border-[black]-200"
                              : "text-gray-900"
                          } bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100  focus:z-10 focus:text-blue-700`}
                          disabled={product.packedQuantity === 0}
                        >
                          <Icon name="minus" size="16" fill="#6F767E" />
                        </button>
                        <button
                          onClick={() => handleItemPack(product)}
                          className={`px-2 py-2 text-sm font-medium ${
                            darkMode.value
                              ? "text-[black] hover:border border-[black]-200"
                              : "text-gray-900"
                          } bg-white border border-gray-200 rounded-r-lg hover:bg-gray-100  focus:z-10 focus:text-blue-700`}
                          disabled={product.packedQuantity === product.quantity}
                        >
                          <Icon name="add" size="16" fill="#6F767E" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <Modal
          outerClassName={styles.outer}
          visible={visibleModal}
          onClose={() => setVisibleModal(false)}
        >
          <div className={styles.details}>
            <div className={cn("title-purple", styles.title)}>Notes</div>

            <div className=" p-4 mt-[20px] rounded-lg">
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <img
                    src={Avatar}
                    alt="Avatar"
                    className="w-10 h-10 object-cover"
                  />
                  <p className="font-semibold ml-[13px]">
                    {orderData?.Customer?.first_name ||
                    orderData?.Customer?.last_name
                      ? `${orderData?.Customer?.first_name || ""} ${
                          orderData?.Customer?.last_name || ""
                        }`.trim()
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="mt-6">
                <p className="text-gray-600 text-[14px]">
                  {orderData?.OrderDetails?.note || "No notes from customer"}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      </Card>
    </>
  );
};

export default OrderLineItems;
